.dashboard-container {
    padding: 2rem;
    background-color: var(--primary-color);
    min-height: 100vh;
    color: white;
  }
  
  .dashboard-container h1 {
    margin-bottom: 2rem;
  }
  
  .dashboard-nav {
    display: flex;
    gap: 1rem;
  }
  
  .dashboard-link {
    padding: 0.75rem 1.5rem;
    background-color: var(--secondary-color);
    color: white;
    text-decoration: none;
    border-radius: 4px;
    transition: background-color 0.3s ease;
  }
  
  .dashboard-link:hover {
    background-color: #0f2d4a;
  }

  .logout-button {
    margin-top: 1rem;
    padding: 0.5rem 1rem;
    background-color: #ff4136;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .logout-button:hover {
    background-color: #ff291f;
  }