.main-footer {
    background-color: var(--primary-color);
    color: var(--text-color);
    padding: 2rem 0;
    margin-top: auto;
  }
  
  .footer-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  
  .social-links {
    margin-bottom: 1rem;
  }
  
  .social-links a {
    color: var(--text-color);
    font-size: 1.5rem;
    margin: 0 0.5rem;
    transition: color 0.3s ease;
  }
  
  .social-links a:hover {
    color: var(--secondary-color);
  }
  
  .slogan {
    font-style: italic;
    font-size: 1.2rem;
    margin: 0;
  }
  
  @media (min-width: 768px) {
    .footer-content {
      flex-direction: row;
      justify-content: space-between;
    }
  
    .social-links {
      margin-bottom: 0;
    }
  }