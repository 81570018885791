:root {
    --primary-color: #133E65;
    --secondary-color: #FF660F;
    --text-color: #FFFFFF;
    --background-color: #F5F5F5;
  }

  body::before {
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('/images/giraffe-background.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    opacity: 0.1;  /* Ajustez cette valeur entre 0 et 1 pour l'opacité désirée */
    z-index: -1;
    pointer-events: none;
  }
  
  body {
    position: relative;
    background-color: var(--background-color);  /* Votre couleur de fond principale */
    font-family: 'Arial', sans-serif;
    color: var(--primary-color);
    line-height: 1.6;
  }
  
  h1, h2, h3, h4, h5, h6 {
    font-family: 'Helvetica Neue', Arial, sans-serif;
    font-weight: bold;
  }
  
  .container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
  }
  
  /* Styles responsives */
  @media (max-width: 768px) {
    .container {
      padding: 0 10px;
    }
  }