.main-header {
    background-color: var(--primary-color);
    padding: 1rem 0;
  }
  
  .header-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .logo-container {
    flex-shrink: 0;
  }
  
  .logo {
    width: 200px; /* Ajustez selon vos besoins */
    height: auto;
    max-width: 100%;
  }
  
  .main-header nav ul {
    list-style-type: none;
    display: flex;
    justify-content: flex-end;
    padding: 0;
    margin: 0;
  }
  
  .main-header nav ul li {
    margin-left: 20px;
  }
  
  .main-header nav ul li a {
    color: var(--text-color);
    text-decoration: none;
    font-weight: bold;
    transition: color 0.3s ease;
  }
  
  .main-header nav ul li a:hover {
    color: var(--secondary-color);
  }
  
  @media (max-width: 768px) {
    .header-content {
      flex-direction: column;
    }
  
    .logo-container {
      margin-bottom: 1rem;
      text-align: center;
    }
  
    .logo {
      width: 80px; /* Taille réduite pour mobile */
    }
  
    .main-header nav ul {
      flex-direction: column;
      align-items: center;
    }
  
    .main-header nav ul li {
      margin: 10px 0;
    }
    .login-link a {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 30px;
        height: 30px;
        background-color: var(--secondary-color);
        border-radius: 50%;
        transition: background-color 0.3s ease;
      }
      
      .login-link a:hover {
        background-color: var(--text-color);
        color: var(--primary-color);
      }
      

  }