.hero {
    background-color: var(--primary-color);
    color: var(--text-color);
    padding: 4rem 0;
    text-align: center;
  }
  
  .hero h1 {
    font-size: 2.5rem;
    margin-bottom: 1rem;
  }
  
  .featured-content {
    padding: 4rem 0;
  }
  
  .content-box {
    background-color: #FFFFFF;
    border-radius: 8px;
    padding: 2rem;
    margin-bottom: 2rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .content-box h2 {
    color: var(--primary-color);
    margin-bottom: 1rem;
  }
  
  .content-box img {
    width: 100%;
    height: auto;
    border-radius: 4px;
    margin-bottom: 1rem;
  }
  
  @media (min-width: 768px) {
    .featured-content .container {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 2rem;
    }
  }